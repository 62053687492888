import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Main from './Main';


const App = () => {
  

return (
  <div className="App">
     <Router>
      
          <Route path="/" component={Main}/>
    </Router>
  </div>
);
}

export default App;
