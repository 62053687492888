
import axios from 'axios';

import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const httpClient = axios.create({
  baseURL: document.location.hostname == "export-reservation.menual.net"
    ? "https://menual.azurewebsites.net"
    : "https://menualdev.azurewebsites.net",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  }
});

const serializeJSON = (data) => {
  return Object.keys(data)
    .map(function (keyName) {
      return (
        encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
      );
    })
    .join('&');
}


// First, checks if it isn't implemented yet.
if (!String.prototype.format) {
    String.prototype.format = function() {
      var args = arguments;
      return this.replace(/{(\d+)}/g, function(match, number) { 
        return typeof args[number] != 'undefined'
          ? args[number]
          : match
        ;
      });
    };
  }

const lang = {
    en : {
        "name" : "Name",
        "phone" : "Telephone",
        "numberofpeople" : "Number Of People",
        "reservedTables" : "Reserved Tables",
        "time" : "Time",
        "notes" : "Notes"
    },
    he : {
        "name" : "שם",
        "phone" : "טלפון",
        "numberofpeople" : "מספר אנשים",
        "reservedTables" : "מקומות ישיבה שמורים",
        "time" : "שעה",
        "notes" : "הערות"
    }
}


const Main = () => {

    const query = useQuery()
    let date = query.get("date")
    let shift = query.get("shift")
    let token = query.get("token")
    let locationID = query.get("locationID")
    let locale = query.get("locale")
    let completed = query.get("completed")
    let approved = query.get("approved")
    let search = query.get("search")

    const [Reservations, setReservations] = useState([])

    if(!locale){
        locale = 'he'
    }

    console.log("reservations params", token)
    const getReservations = () => {
        httpClient.post("/ws/Reservation.asmx/GetReservations", serializeJSON({
            Token  : token, 
            LocationID : locationID,
            Search : '',
            Performant : true,
            Completed : completed,
            FilterDate : date,
            Shift : shift,
           // Locale : locale,
            //Limit : 1000,
            OrderBy : "TimeStamp asc",
            
            Approved : approved,
           
        }))
          .then(({ data }) => {
            console.log("getReservations Data", data)
            setReservations(data.Reservations)
          })
      }
    
      useEffect(() => {
        getReservations()
      }, [])

    return (
        <div className="container">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{lang[locale]["name"]}</th>
            <th scope="col">{lang[locale]["phone"]}</th>
            <th scope="col">{lang[locale]["numberofpeople"]}</th>
            <th scope="col">{lang[locale]["reservedTables"]}</th>
            <th scope="col">{lang[locale]["time"]}</th>
            <th scope="col">{lang[locale]["notes"]}</th>
          </tr>
        </thead>
        <tbody>
          {Reservations.map((reseration, key) => <tr key={key}>
            <th scope="row">{key  + 1}</th>
            <td>{reseration.Name}</td>
            <td>{reseration.Telephone}</td>
            <td>{reseration.NumberOfPeople}</td>
            <td>{reseration.TableReservation.map((table) => {
                return table.TableNumber
            }).join(",")}</td>
            <td>{reseration.TimeStamp}</td>
            <td>{reseration.Notes}</td>
          </tr>)}

        </tbody>
      </table>
    </div>
    )
}

export default Main
